<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">Space Deletion</span>
        </v-card-title>
        <v-card-text>
            <v-row align="center">
                <v-col cols="3">
                    <v-subheader>SPACE NAME</v-subheader>
                </v-col>
                <v-col cols="9" class="subtitle-2">{{ spaceName }}</v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="3">
                    <v-subheader>SPACE DESCRIPTION</v-subheader>
                </v-col>
                <v-col cols="9" class="subtitle-2">{{ spaceDescription }}</v-col>
            </v-row>
            <v-alert max-width="1000" outlined color="error" prominent type="warning" class="mt-5">
                <span class="font-weight-bold">Here is what happens when you delete a space</span>
                <ul class="mt-3">
                    <li class="subtitle-2">All files, data, and applications stored in all instances in this space will be deleted permanently.</li>
                    <li class="subtitle-2">All members of this space will lose their access and data.</li>
                </ul>
                <v-divider class="my-4 secondary" style="opacity: 0.22"></v-divider>
                <v-checkbox class="secondary--text" color="error" v-model="agreeBox" label="I understand"></v-checkbox>
            </v-alert>
        </v-card-text>
        <v-card-actions>
            <div class="d-flex flex-column justify-center align-center w-100">
                <DeleteButton
                    class="mb-3"
                    :objectType="nuvolosObjectTypes.SPACE"
                    :objectName="spaceName"
                    :isAsyncDeletion="true"
                    buttonName="Delete space"
                    :cancelRoute="{
                        name: 'snapshot-overview',
                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                    }"
                    :apiURL="'/spaces/' + this.$route.params.sid"
                    :id="parseInt($route.params.sid, 10)"
                    :higherLevelId="parseInt($route.params.oid, 10)"
                    :routeAfter="{ name: 'home-dashboard', params: { oid: $route.params.oid } }"
                    fetchString="orgStore/fetchOrgSpaces"
                    @error="errorMessage($event.error)"
                    :disabled="!agreeBox"></DeleteButton>
                <v-alert :value="this.error" color="error" icon="warning" class="mt-4" outlined>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">{{ errorContent }}</span>
                        <span>
                            For more information on space creation issues, check the troubleshooting documentation
                            <v-btn
                                class="font-weight-bold"
                                small
                                text
                                color="error"
                                href="https://docs.nuvolos.cloud/faqs/troubleshooting/administration-troubleshooting/i-cant-delete-a-space"
                                target="_blank">
                                here
                            </v-btn>
                        </span>
                    </div>
                </v-alert>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { isMasterInstance } from '@/utils'
import { enumsData } from '@/mixins/enums'
const DeleteButton = () => import('@/components/DeleteButton')

export default {
    mixins: [enumsData],
    components: {
        DeleteButton
    },
    data() {
        return {
            error: false,
            errorContent: 'An error has occurred',
            loading: false,
            agreeBox: false
        }
    },
    methods: {
        errorMessage: function (error) {
            if (error) {
                this.error = true
                if (isMasterInstance(this.instanceName)) {
                    this.errorContent = 'Master instance is not allowed to be deleted'
                } else {
                    this.errorContent = 'An error has occurred'
                }
            }
        }
    },
    computed: {
        spaceName() {
            return this.$store.getters['spaceStore/spaceLongNameById'](this.$route.params.sid)
        },
        instanceName() {
            return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
        },
        spaceDescription() {
            return this.$store.getters['spaceStore/spaceDescriptionById'](this.$route.params.sid)
        }
    }
}
</script>
